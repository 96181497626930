<template>
  <div v-if="userInfo !== null">
    <div class="buttonarea" v-if="isSameUser">
      <v-btn
        title="Edit"
        icon
        class="d-inline-block opac-btn"
        @click="openUserSettings"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn title="Share" icon class="d-inline-block opac-btn" @click="">
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </div>
    <v-row no-gutters class="ma-0 pa-0">
      <v-col cols="12" xl="2" lg="2" md="4" sm="12"  class="px-3 text-center mt-10">
        <v-avatar color="orange accent-1 mt-5" size="150px">
          <AsyncAvatarImage
            :username="userInfo.name"
            :collection="collection"
          />
        </v-avatar>
        <h3>{{userInfo.name}}</h3>
      </v-col>
      <v-col clos="12" xl="10" lg="10" md="8" sm="12">
        <h1 style="font-size: 25pt" class="mt-1 text-center">
          {{ userInfo.display }}
        </h1>
        <markdown-it-vue
          class="pa-2 text-justify"
          :content="userInfo.bio"
          v-if="userInfo.bio"
          :collection="collection"
        />
        <v-divider class="mb-2" />
        <span v-if="userInfo.email" class="font-weight-bold ml-2"
          >Public email:</span
        >
        {{ userInfo.email }}
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
export default {
  data: () => ({
    userInfo: null
  }),
  props: {
    collection: Object,
    currentUser: Object,
    repo: Object
  },
  components: { AsyncAvatarImage },
  created () {
    this.getUserInfo()
  },
  mounted () {},
  methods: {
    openUserSettings () {
      this.$parent.$parent.$parent.openUserSettings()
    },
    async getUserInfo () {
      await this.repoStatus('ready', 'getUserInfo')
      this.userInfo = await this.repo.get(
        `org.couchdb.user:${this.$route.params.profilename}`
      )
    },
    async repoStatus (status = 'ready', waiter = 'unknown') {
      if (!this.repo) {
        console.debug(waiter, 'is waiting for repo status to be', status)
        while (!this.repo) {
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
        console.debug(waiter, 'resuming -- repo is now', status)
      }
    }
  },
  computed: {
    isSameUser () {
      return this.currentUser.name == this.$route.params.profilename
    }
  },
  watch: {}
}
</script>
<style scoped>

</style>
